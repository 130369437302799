<template>
  <v-card id="card-modal" class="py-4 elevation-4">
    <v-card-title class="justify-center">
      <div>
        <h3 class="primary--text">
          {{ $t("components.views.trip.no_offer_assign.modal_create_vehicle.new_vehicle") }}
        </h3>
      </div>
      <v-btn depressed text color="primary" class="cont" @click="closeModal()">
        <v-icon class="mr-1">fa-solid fa-xmark</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider class="mt-2 mb-3 mx-10 secondary" />
    <v-card-text>
      <v-form v-model="valid">
        <v-container fluid>
          <v-row class="justify-center">
            <v-col cols="10" class="pb-0">
              <v-text-field
                v-model="id"
                required
                disabled
                dense
                outlined
                hide-details
                :label="
                  $t('components.views.trip.no_offer_assign.modal_create_vehicle.license_plate')
                "
                :placeholder="
                  $t('components.views.trip.no_offer_assign.modal_create_vehicle.license_plate')
                "
                :rules="[
                  required(
                    $t('components.views.trip.no_offer_assign.modal_create_vehicle.license_plate'),
                    0
                  ),
                ]"
                prepend-icon="fa-solid fa-track"
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="10" class="pb-0">
              <SelectAutocomplete
                ref="vehicleClassList"
                v-model="vehicleNew.typeVehicle"
                :url="urlVehicleClassList"
                return-object
                item-text="name"
                dense
                item-value="idVehicleClass"
                maxlength="10"
                prepend-icon="fa-solid fa-truck-moving"
                :label="
                  $t('components.views.trip.no_offer_assign.modal_create_vehicle.typeVehicle')
                "
                required
                :placeholder="
                  $t('components.views.trip.no_offer_assign.modal_create_vehicle.typeVehicle')
                "
                :rules="[
                  required(
                    $t('components.views.trip.no_offer_assign.modal_create_vehicle.typeVehicle'),
                    1
                  ),
                ]"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="10" class="pb-0">
              <SelectAutocomplete
                ref="vehicleFleetType"
                v-model="vehicleNew.fleetType"
                :url="urlVehicleFleetType"
                return-object
                dense
                item-text="name"
                maxlength="10"
                item-value="idMaster"
                prepend-icon="fa-solid fa-arrow-up"
                :label="$t('components.views.trip.no_offer_assign.modal_create_vehicle.fleetType')"
                required
                :placeholder="
                  $t('components.views.trip.no_offer_assign.modal_create_vehicle.fleetType')
                "
                :rules="[
                  required(
                    $t('components.views.trip.no_offer_assign.modal_create_vehicle.fleetType'),
                    1
                  ),
                ]"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="10" class="pb-0">
              <SelectAutocomplete
                ref="vehicleTypology"
                v-model="vehicleNew.typology"
                :url="urlVehicleTypology"
                return-object
                dense
                item-text="name"
                maxlength="10"
                item-value="idVehicleTypology"
                prepend-icon="fa-solid fa-minus"
                :label="$t('components.views.trip.no_offer_assign.modal_create_vehicle.typology')"
                required
                :placeholder="
                  $t('components.views.trip.no_offer_assign.modal_create_vehicle.typology')
                "
                :rules="[
                  required(
                    $t('components.views.trip.no_offer_assign.modal_create_vehicle.typology'),
                    1
                  ),
                ]"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row class="justify-center mt-6">
            <v-btn color="secondary" :disabled="active || !valid" @click="newVehicle()" depressed>
              <v-icon small class="fa-solid fa-bookmark mr-1" />
              {{ $t("components.views.trip.no_offer_assign.modal_create_vehicle.save") }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import validate from "@/plugins/validate";

export default {
  props: {
    idLicensePlate: {
      type: String,
      default: "",
    },
    idCompany: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {
      valid: false,
      ...validate,
      active: false,
      vehicleNew: {
        typeVehicle: "",
        fleetType: "",
        typology: "",
        id: "",
      },
    };
  },
  computed: {
    id() {
      return this.idLicensePlate;
    },
    urlVehicleClassList() {
      return `/Vehicles/vehicleClassList`;
    },
    urlVehicleFleetType() {
      return `/Vehicles/vehicleFleetList`;
    },
    urlVehicleTypology() {
      return `/Vehicles/vehicleTypologyList`;
    },
  },
  mounted() {
    this.resetFields();
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    resetFields() {
      this.$nextTick(() => {
        this.vehicleNew = Object.assign(
          {},
          {
            typeVehicle: "",
            fleetType: "",
            typology: "",
            id: "",
          }
        );
      });
    },

    closeModal() {
      this.resetFields();
      this.$emit("closeForm");
    },

    async newVehicle() {
      this.active = true;

      this.vehicleNew.id = this.id;

      const user = JSON.parse(store.getters["auth/user"]);

      let vehicle = {
        licensePlate: this.id,
        idVehicleClass: this.vehicleNew.typeVehicle.idVehicleClass,
        idMasterFleet: this.vehicleNew.fleetType.idMaster,
        idCompany: this.idCompany,
        idVehicleTypology: this.vehicleNew.typology.idVehicleTypology,
      };

      await this.axios
        .post("/Vehicles/Create/", vehicle)
        .then(() => {
          this.showSnack({
            text: this.$t(
              "components.views.trip.no_offer_assign.modal_create_vehicle.vehicle_created_success"
            ),
            title: this.$t("components.views.trip.no_offer_assign.modal_create_vehicle.success"),
            name: "success",
          });

          //Envio al Edit el vehiculo creado
          this.$emit("vehicleCreated", this.vehicleNew.id);

          //Reset al Botón de Guardar
          this.active = false;

          this.closeModal();
        })
        .catch((error) => {
          this.closeModal();
          this.showSnack({
            text: error.response.data,
            title: this.$t(
              "components.views.trip.no_offer_assign.modal_create_vehicle.vehicle_created_error"
            ),
            name: "error",
          });
          this.active = false;
        });
    },
  },
};
</script>

<style scoped>
#card-modal {
  border: 3px solid #0b0045 !important;
}

.v-divider {
  border-width: 1px !important;
}

#card-modal p {
  font-size: 1.17em !important;
}

.cont {
  position: absolute;
  right: 10px;
}
</style>
