var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"py-4 elevation-4",attrs:{"id":"card-modal"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('div',[_c('h3',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t("components.views.trip.no_offer_assign.modal_create_vehicle.new_vehicle"))+" ")])]),_c('v-btn',{staticClass:"cont",attrs:{"depressed":"","text":"","color":"primary"},on:{"click":function($event){return _vm.closeModal()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("fa-solid fa-xmark")])],1)],1),_c('v-divider',{staticClass:"mt-2 mb-3 mx-10 secondary"}),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"required":"","disabled":"","dense":"","outlined":"","hide-details":"","label":_vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.license_plate'),"placeholder":_vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.license_plate'),"rules":[
                _vm.required(
                  _vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.license_plate'),
                  0
                ),
              ],"prepend-icon":"fa-solid fa-track"},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('SelectAutocomplete',{ref:"vehicleClassList",attrs:{"url":_vm.urlVehicleClassList,"return-object":"","item-text":"name","dense":"","item-value":"idVehicleClass","maxlength":"10","prepend-icon":"fa-solid fa-truck-moving","label":_vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.typeVehicle'),"required":"","placeholder":_vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.typeVehicle'),"rules":[
                _vm.required(
                  _vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.typeVehicle'),
                  1
                ),
              ],"hide-details":""},model:{value:(_vm.vehicleNew.typeVehicle),callback:function ($$v) {_vm.$set(_vm.vehicleNew, "typeVehicle", $$v)},expression:"vehicleNew.typeVehicle"}})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('SelectAutocomplete',{ref:"vehicleFleetType",attrs:{"url":_vm.urlVehicleFleetType,"return-object":"","dense":"","item-text":"name","maxlength":"10","item-value":"idMaster","prepend-icon":"fa-solid fa-arrow-up","label":_vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.fleetType'),"required":"","placeholder":_vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.fleetType'),"rules":[
                _vm.required(
                  _vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.fleetType'),
                  1
                ),
              ],"hide-details":""},model:{value:(_vm.vehicleNew.fleetType),callback:function ($$v) {_vm.$set(_vm.vehicleNew, "fleetType", $$v)},expression:"vehicleNew.fleetType"}})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('SelectAutocomplete',{ref:"vehicleTypology",attrs:{"url":_vm.urlVehicleTypology,"return-object":"","dense":"","item-text":"name","maxlength":"10","item-value":"idVehicleTypology","prepend-icon":"fa-solid fa-minus","label":_vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.typology'),"required":"","placeholder":_vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.typology'),"rules":[
                _vm.required(
                  _vm.$t('components.views.trip.no_offer_assign.modal_create_vehicle.typology'),
                  1
                ),
              ],"hide-details":""},model:{value:(_vm.vehicleNew.typology),callback:function ($$v) {_vm.$set(_vm.vehicleNew, "typology", $$v)},expression:"vehicleNew.typology"}})],1)],1),_c('v-row',{staticClass:"justify-center mt-6"},[_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.active || !_vm.valid,"depressed":""},on:{"click":function($event){return _vm.newVehicle()}}},[_c('v-icon',{staticClass:"fa-solid fa-bookmark mr-1",attrs:{"small":""}}),_vm._v(" "+_vm._s(_vm.$t("components.views.trip.no_offer_assign.modal_create_vehicle.save"))+" ")],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }